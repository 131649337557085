<template>
  <div class="placement-grid">
    <div
        v-for="placement in latestRankedPlacements"
        :class="['placement-grid__placement', placement > 4 ? '--loss' : placement === 1 ? '--win' : '--top4']"
    >
      <span>{{ placement }}</span>
    </div>

    <div
        v-for="_ in 20 - latestRankedPlacements.length"
        class="placement-grid__placement --empty"
    />
  </div>
</template>

<script lang="ts" setup>
import { QueueID } from "~/types/riot";
import { SummonerRankedStats } from "~/types/summoner";

interface Props {
  summonerRankedStats: SummonerRankedStats[];
  amount?: number;
}

const props = withDefaults(defineProps<Props>(), {
  amount: 20
});

const latestRankedPlacements = computed(() => props.summonerRankedStats.find(d => d.queueId === QueueID.RANKED)?.latestPlacements.slice(0, 20) || []);
</script>

<style lang="scss" scoped>
.placement-grid {
  display: grid;
  grid-template-columns: repeat(10, minmax(2rem, 1fr));
  gap: 0.6rem;

  @include breakpoint('sm') {
    grid-template-columns: repeat(10, minmax(2.4rem, 3rem));
    justify-content: center;
  }

  .placement-grid__placement {
    aspect-ratio: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: grey;
    font-weight: var(--FontWeight-Medium);
    border-radius: var(--BorderRadius-S);

    span {
      font-size: 1.1rem;
      margin-top: 0.3rem;
    }

    &.--loading, &.--empty {
      opacity: 0.2;

      span {
        margin-top: 0;
      }
    }

    &.--win {
      background-color: #11b288;
      color: white;
    }

    &.--loss {
      background-color: #d6d6d6;
      color: grey;
    }

    &.--top4 {
      background-color: #207ac7;
      color: white;
    }
  }
}
</style>